import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import Select from "react-select";
import List_Add_Up from './List_Add_Up';
import { getDrpData } from '../../hooks/Api';
import { getShowingWithOutTime, toastifyError } from '../../Comman/Utility';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const List = () => {

    const [drpData, setDrpData] = useState([]);
    const [slipData, setSlipData] = useState([]);
    const [id, setId] = useState('');
    const [updCount, setUpdCount] = useState(0)

    const [value, setValue] = useState({
        WebbridgeNo: '',
        RSTDate: '',
        RSTDateTo: '',
    })

    useEffect(() => {
        get_DrpData()
    }, []);

    const get_DrpData = async () => {
        getDrpData('Slip/DropDown', value).then((res) => {
            // console.log(res)
            if (res?.data?.Table.length > 0) {
                setDrpData(threeColArrayWithCode(res?.data?.Table, 'ID', 'Company_Name', 'WebbridgeNo'))
            } else {
                setDrpData([])
            }
        })
    }

    const searchData = () => {
        getDrpData('Slip/GetData', value).then((res) => {
            console.log(res)
            if (res) {
                if (res?.data?.Table.length > 0) {
                    setSlipData(res?.data?.Table);
                }
            } else {
                // notify();
                toastifyError("No Data Available!")
                setSlipData([]);
            }
        })
    }

    const notify = () => toast.error("No Data Available");

    const columns = [
        {
            name: 'RSTNo',
            selector: (row) => row.RSTNo,
            sortable: true,
        },
        {
            name: 'RSTDate',
            selector: (row) => row.RSTDate,
            sortable: true,
        },
        {
            name: 'BuyerName',
            selector: (row) => row.BuyerName,
            sortable: true,
        },
        {
            name: 'VehicleType',
            selector: (row) => row.VehicleType,
            sortable: true,
        },
        {
            name: 'VehicleNo',
            selector: (row) => row.VehicleNo,
            sortable: true,
        },
        {
            name: 'Tareweight',
            selector: (row) => row.Tareweight,
            sortable: true,
        },
        {
            name: 'Grossweight',
            selector: (row) => row.Grossweight,
            sortable: true,
        },
        {
            name: 'Netweight',
            selector: (row) => row.Netweight,
            sortable: true,
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                <Link to={'#'} onClick={() => { setId(row.ID); setUpdCount(updCount + 1); }} className="btn btn-sm bg-line text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#ListModal">
                    <i className="fa fa-edit"></i>
                </Link>
                {/* <Link to={'#'} className="btn btn-sm bg-line text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#ListModal">
                    <i className="fa fa-print"></i>
                </Link> */}
            </>
        }
    ];

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
        } else setValue({
            ...value,
            [name]: null
        })
    }

    return (
        <>
            <div className="section-body view_page_design pt-3">
                <div className="col-12 col-sm-12">
                    <div className="card Agency">
                        <div className="card-body">
                            <div className="btn-box text-right ">
                                <Link to={'/'}>
                                    <button type="button" data-dismiss="modal" class="btn btn-sm  text-white mr-1" style={{ background: 'brown', marginTop: '-15px' }}>Logout</button>
                                </Link>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6 col-md-6 col-lg-6 mt-1">
                                    <div className=" dropdown__box">
                                        <Select
                                            name='WebbridgeNo'
                                            value={drpData?.filter((obj) => obj.value === value?.WebbridgeNo)}
                                            isClearable
                                            options={drpData}
                                            onChange={(e) => ChangeDropDown(e, 'WebbridgeNo')}
                                            placeholder="Select..."
                                        />
                                    </div>
                                </div>
                                <div className="col-1 col-md-1 col-lg-1 mt-1"></div>
                                <div className="col-6 col-md-6 col-lg-2 mt-1">
                                    <div className=" dropdown__box">
                                        <DatePicker
                                            name='RSTDate'
                                            className='form-control'
                                            id='RSTDate'
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => { setValue({ ...value, ['RSTDate']: date ? getShowingWithOutTime(date) : null }) }}
                                            timeInputLabel
                                            isClearable={value?.RSTDate ? true : false}
                                            placeholderText={value?.RSTDate ? value?.RSTDate : 'Select...'}
                                            selected={value?.RSTDate && new Date(value?.RSTDate)}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete='Off'
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-2 mt-1">
                                    <div className=" dropdown__box">
                                        <DatePicker
                                            name='RSTDateTo'
                                            onChange={(date) => {
                                                setValue({
                                                    ...value,
                                                    ['RSTDateTo']: date ? getShowingWithOutTime(date) : null,
                                                })
                                            }}
                                            selected={value?.RSTDateTo && new Date(value?.RSTDateTo)}
                                            className='form-control'
                                            dateFormat="dd/MM/yyyy"
                                            timeInputLabel
                                            isClearable={value?.RSTDateTo ? true : false}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete='Off'
                                            disabled={value?.RSTDate ? false : true}
                                            maxDate={new Date()}
                                            placeholderText='Select...'
                                        />
                                    </div>
                                </div>
                                <div className="btn-box text-right mt-3 pt-1">
                                    <button type="button" onClick={searchData} data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Search</button>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 mb-2 p-0" >
                                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                    <p className="p-0 m-0 d-flex align-items-center text-white">List</p>
                                    <p className="p-0 m-0">
                                        <Link to={'#'} data-toggle="modal" data-target="#ListModal" className="btn btn-sm  text-white px-2 py-0" >
                                            <i className="fa fa-print"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <DataTable
                                    dense
                                    columns={columns}
                                    data={slipData && slipData}
                                    pagination
                                    selectableRowsHighlight
                                    highlightOnHover
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <List_Add_Up {...{ id, updCount }} />
            <ToastContainer />
        </>
    )
}

export default List

const threeColArrayWithCode = (data, Id, Code, col3) => {
    const result = data?.map((sponsor) =>
        ({ value: sponsor[col3], label: sponsor[col3] + ' - ' + sponsor[Code], id: sponsor[col3] })
    )
    return result
}