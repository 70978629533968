import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchPostData } from '../../hooks/Api'
import api from '../../axios/axios';
import axios from 'axios';

const Login2 = () => {

    const navigate = useNavigate()

    const [value, setValue] = useState({
        UserName: "",
        Password: "",
    })

    const HandleChange = (e) => {
        if (e) {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const LoginUser = async () => {
        fetchPostData('Slip/Login', value).then((res) => {
            if (res.success) {
                navigate('/List')
            } else {
                alert("Invalid UserName Password")
            }
        })

        // try {
        //     const response = await api.post('Slip/Login', value)
        //     console.log('Data:', response);
        // } catch (error) {
        //     console.error('Error', error);
        // }

        // fetch('https://upmoslip.arustu.com/api/Slip/Login', {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({
        //         value
        //     }),
        // }).then((res) => res.json()).then((data) => {
        //     console.log(data)
        //     // Do some stuff ...
        // }).catch((err) => console.log(err));

    }

    // http://localhost:43815/api/Slip/Login
    // UserName:
    // Password:

    return (
        <>
            <section className="pt-5 pb-5 mt-0 align-items-center d-flex bg-dark banner" style={{ minHeight: '100vh', backgroundSize: 'cover', backgroundImage: 'url(https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=c0d43804e2c7c93143fe8ff65398c8e9)' }}>
                <div className="container-fluid">
                    <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">
                        <div className="col-12 col-md-5 col-lg-3   h-50 ">
                            <div className="card shadow">
                                <div className="card-body mx-auto">
                                    <h4 className="card-title mt-3 text-center">Login Account</h4>
                                    <form>
                                        <div className="form-group input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"> <i className="fa fa-user" /> </span>
                                            </div>
                                            <input name="UserName" id='UserName' value={value?.UserName} onChange={HandleChange} className="form-control" placeholder="Full name" type="text" />
                                        </div>
                                        <div className="form-group input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"> <i className="fa fa-lock" /> </span>
                                            </div>
                                            <input name="Password" id='Password' value={value?.Password} onChange={HandleChange} className="form-control" placeholder="Password" type="password" />
                                        </div>
                                        <div className="form-group">
                                            {/* <Link to={'/List'}> */}
                                            <button type="button" onClick={LoginUser} className="btn btn-primary btn-block"> Login </button>
                                            {/* </Link> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Login2