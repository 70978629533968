import logo from './logo.svg';
import './App.css';
import Table from './Components/Table';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login2 from './Components/Login/Login2';
import List from './Components/List/List';
import "react-datepicker/dist/react-datepicker.css";


function App() {

  return (
    <>
      <BrowserRouter >
        <div id="main_content">
          <Routes>
            <Route exact path="/" element={<Login2 />} />
            <Route exact path="/List" element={<List />} />
            {/* <Table /> */}
            {/* <Login /> */}
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
