import React, { memo, useEffect, useState } from 'react'
import logo from './logo.png'
import { getDrpData } from '../../hooks/Api'

const List_Add_Up = (props) => {

    const { id, updCount } = props
    const [slipData, setSlipData] = useState([]);

    useEffect(() => {
        if (id) {
            searchData(id)
        }
    }, [id, updCount]);

    const searchData = (id) => {
        const val = {
            ID: id
        }
        getDrpData('Slip/GetSingleData', val).then((res) => {
            if (res?.data?.Table.length > 0) {
                setSlipData(res?.data?.Table[0]);
            } else {
                setSlipData([])
            }
        })
    }

    // http://localhost:43815/api/Slip/GetSingleData
    // ID:

    return (
        <>
            <div class="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="ListModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal-dialog modal-xl modal-dialog-centered rounded" role="document">
                    <div class="modal-content" style={{ marginTop: '-20px', marginBottom: '-20px' }}>
                        <div class="modal-body">
                            <div className="">
                                <div className="row">
                                    <div class="col-12 col-md-12 bb" style={{ marginTop: '-10px' }}>
                                        <h5 className='text-center company-name'>{slipData?.CompanyName}</h5>
                                        <p className='text-center company-add mb-0'>{slipData?.CompanyAddress}</p>
                                    </div>
                                </div>
                                <div className="row bb mt-1">
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-3">
                                            <label htmlFor="" className='label-list'>Ticket No </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>10</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-3">
                                            <label htmlFor="" className='label-list'>EMM No </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.EMM}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-9 d-flex">
                                        <div class="col-3 col-md-3 col-lg-2">
                                            <label htmlFor="" className='label-list'>Buyer Name </label>
                                        </div>
                                        <div class="col-9 col-md-9 col-lg-12">
                                            <span className='label-data'>{slipData?.BuyerName}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-9 d-flex">
                                        <div class="col-3 col-md-3 col-lg-2">
                                            <label htmlFor="" className='label-list'>Location </label>
                                        </div>
                                        <div class="col-9 col-md-9 col-lg-12">
                                            <span className='label-data'>Dummy text</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-9 d-flex">
                                        <div class="col-3 col-md-3 col-lg-2">
                                            <label htmlFor="" className='label-list'>Mineral</label>
                                        </div>
                                        <div class="col-9 col-md-9 col-lg-12">
                                            <span className='label-data'>{slipData?.MineralName}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-3">
                                            <label htmlFor="" className='label-list'>Vehicle No </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.VehicleNo}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-8 col-md-8 col-lg-3">
                                            <label htmlFor="" className='label-list'>No. Of Tyres </label>
                                        </div>
                                        <div class="col-3 col-md-3 col-lg-4">
                                            <span className='label-data'>{slipData?.NumberofTyres}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-3">
                                            <label htmlFor="" className='label-list'>Vehicle Type </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.VehicleType}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-8 col-lg-3">
                                            <label htmlFor="" className='label-list'>Transport </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.Transport}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row bb mt-2">
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-4">
                                            <label htmlFor="" className='label-list'>Gross Weight(Kg) </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.Grossweight}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-3">
                                            <label htmlFor="" className='label-list'>Gross Wt Time </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.GrossDate}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-4">
                                            <label htmlFor="" className='label-list'>Tare Weight(Kg) </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.Tareweight}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-3">
                                            <label htmlFor="" className='label-list'>Tare Wt Time </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.TareDate}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-4">
                                            <label htmlFor="" className='label-list'>Net Weight(Kg) </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>{slipData?.Netweight}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-6 d-flex">
                                        <div class="col-6 col-md-6 col-lg-3">
                                            <label htmlFor="" className='label-list'>Charge(Rs.) </label>
                                        </div>
                                        <div class="col-4 col-md-4 col-lg-4">
                                            <span className='label-data'>10</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mt-2">
                                        <div className="box">
                                            {/* <img src={slipData?.Camera1} alt="" className='logo img-fluid' /> */}
                                            <img src={`data:image/png;base64,${slipData?.Camera1}`} alt="" className='logo img-fluid' />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-2">
                                        <div className="box">
                                            <img src={`data:image/png;base64,${slipData?.Camera2}`} alt="" className='logo img-fluid' />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-2">
                                        <div className="box">
                                            <img src={`data:image/png;base64,${slipData?.Camera3}`} alt="" className='logo img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-box text-right mr-2 mb-2">
                            {/* <button type="button" className="btn btn-sm btn-success mr-1">Save</button> */}
                            <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default memo(List_Add_Up)