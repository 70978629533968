import { DecryptedList, EncryptedList } from "../Comman/Utility";
import api from "../axios/axios";

// ------get API Request
export const fetchData = async (url) => {
    try {
        const res = await api.get(url);
        console.log(res)
        const TextData = JSON.parse(res)
        return TextData.Table
    } catch (err) {
        console.log(err);
    }
};

export const getDrpData = async (url, postData) => {
    try {
        const res = await api.post(url, postData);
        console.log(res)
        // const TextData = JSON.parse(res)
        return res.data
        // return res
    } catch (error) {
        return false
        // console.error(' error', error);
    }
};

// -------get DATA API With Post Request
export const fetchPostData = async (url, postData) => {
    try {
        const res = await api.post(url, postData);
        // console.log(res)
        // const TextData = JSON.parse(res)
        return res.data
    } catch (error) {
        console.error(' error', error);
    }
};
