import axios from "axios";

const api = axios.create({
    baseURL: 'https://upmoslip.arustu.com/api/',
    // timeout: 10000, // Set timeout to 10 seconds
    // headers: {
        'Content-Type': 'application/json',
        // You can add any custom headers here
    // },
});

// https://upmoslip.arustu.com/
export default api;