import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastifySuccess = (message) => {
    toast.success(`${message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}

export const toastifyError = (message) => {
    toast.error(`${message}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}

export const getShowingWithOutTime = (dateStr) => {
    // console.log(dateStr)
    // return moment(dateStr).format("MM/DD/YYYY")
    return moment(dateStr).format("YYYY/MM/DD")
}
